import React, { createContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isUserLoggedIn } from '../../selectors/login'
import { userData } from '../../selectors/user'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const isLoggedIn = useSelector(isUserLoggedIn)
  const user = useSelector(userData)

  const userInfo = useMemo(() => ({ isLoggedIn, user }), [isLoggedIn])

  return (
    <UserContext.Provider value={userInfo}>{children}</UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
